<template>
  <div class="side-page">
    <router-link to="/">
      <img class="header" alt="John Cunningham Header" src="/header/white-clear.png" />
    </router-link>
    <div class="card">
      <h1 class="margin-20">Experience</h1>
      <p class="margin-20">Creating the optimal solution requires understanding both the problem and the tools to solve it. In that spirit, I have committed myself to developing a skillset for empathizing with customers and leveraging the latest technology.</p>
      <p class="margin-20">From a technical standpoint, I have gathered experience in full-stack web development [Frontend: React, Vue (TypeScript) | Backend: Node.js, Python] and application development [Flutter, React Native]. I've used AWS and Azure to distribute applications and manage data lakes, and I have experience applying machine learning tools to large datasets [Python, Pandas, Tensorflow, BERT].</p>
      <p class="margin-20">I've worked for both large institutions and startups. I've developed a background in entrepreneurship and design thinking, focusing on how finance transforms people's lives.</p>
      <p class="margin-20">See my resume below for more details.</p>
      <a href="/Resume.pdf" target="_blank" rel="noopener noreferrer">
        <div id="main-link">
          <p>Resume</p>
          <i class="bi bi-arrow-right"></i>
        </div>
      </a>
    </div>
    <router-link id="continue-link" to="/growth">Continue to Growth <i class="bi bi-arrow-right"></i></router-link>
  </div>
</template>